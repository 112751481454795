<template>
  <div>
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5" v-if="profile_cart">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            ตะกร้าสินค้า
          </span>
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-2 pb-0">
        <!--begin::Table-->
        <div class="table-responsive" v-if="profile_cart">
          <table class="table table-borderless table-vertical-center mb-8">
            <div
              v-for="vendor in profile_cart.record.profile_cart"
              v-bind:key="vendor._id"
              v-frag
            >
              <thead>
                <tr>
                  <th>
                    <span style="width: 20px">
                      <label class="checkbox checkbox-single checkbox-all">
                        <input
                          type="checkbox"
                          :value="vendor._id"
                          :checked="
                            checkedAllItem(vendor._id, vendor.items.length)
                          "
                          v-on:change="selectShop($event, vendor)"
                        />&nbsp;
                        <span></span>
                      </label>
                    </span>
                  </th>
                  <th
                    class="pl-0 font-weight-bold text-muted d-flex align-items-center"
                  >
                    <div class="text-overflow" style="width: 90px">
                      {{ vendor.vendor }}
                    </div>
                    <span class="switch switch-sm ml-4 text-nowrap">
                      <label class="d-flex align-items-center">
                        <input
                          type="checkbox"
                          v-model="vendor.guarantee"
                          :checked="vendor.guarantee"
                          @change="enableWoodCart(vendor._id, vendor.guarantee)"
                        />
                        <span class="mr-2"></span>
                        ตีลังไม้
                      </label>
                    </span>
                  </th>
                  <th class="text-center font-weight-bold text-muted">จำนวน</th>
                  <th class="text-right font-weight-bold text-muted">
                    ราคา({{ vendor.currencySign || "¥" }})
                  </th>
                  <th class="text-right pr-0 font-weight-bold text-muted">
                    รวม({{ vendor.currencySign || "¥" }})
                  </th>
                </tr>
              </thead>
              <tbody class="pb-8">
                <tr
                  class="font-weight-boldest"
                  v-for="item in vendor.items"
                  v-bind:key="item._id"
                >
                  <td>
                    <span style="width: 20px">
                      <label class="checkbox checkbox-single">
                        <input
                          type="checkbox"
                          :value="item._id"
                          :checked="checkedItem(vendor._id, item._id)"
                          v-on:change="selectItem($event, vendor, item)"
                        />&nbsp;
                        <span> </span>
                      </label>
                    </span>
                  </td>
                  <td class="pl-0 pt-6 pb-6 align-items-center align-middle">
                    <div class="d-flex">
                      <!--begin::Symbol-->
                      <a :href="item.url" target="_blank">
                        <div
                          class="symbol symbol-40 flex-shrink-0 mr-4 bg-light"
                        >
                          <div
                            class="symbol-label"
                            :style="
                              `background-image: url('${item.mainImage}')`
                            "
                          ></div>
                        </div>
                        <!--end::Symbol-->
                      </a>
                      <div>
                        <a :href="item.url" target="_blank">
                          {{ item.productName }}
                        </a>
                        <div
                          class="opacity-80 font-weight-bold d-block"
                          v-bind:key="`${item._id}${c.title}${c.id}`"
                          v-for="c in item.properties"
                        >
                          <span v-if="c.name">{{ c.name }} : </span>
                          <img
                            v-viewer
                            v-if="c.image"
                            :src="c.image"
                            style="height: 20px"
                            class="align-self-center pointer mr-2"
                            :alt="item.productName"
                          />
                          <span>{{ c.title }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center pt-0 pb-6 align-middle text-nowrap">
                    <InputNumber
                      :defaultValue="item.amount"
                      @onChange="updateAmount(vendor._id, item._id, $event)"
                    />
                  </td>
                  <td class="text-right pt-0 pb-6 align-middle">
                    {{ format_number(item.price, 2)
                    }}{{ vendor.currencySign || "¥" }}
                  </td>
                  <td class="text-right pt-0 pb-6 align-middle">
                    {{ format_number(item.price * item.amount, 2)
                    }}{{ vendor.currencySign || "¥" }}
                  </td>
                  <td class="text-right pt-0 pb-6 align-middle">
                    <button
                      class="btn btn-icon btn-light btn-hover-danger btn-sm"
                      @click="deleteCart(vendor._id, item._id)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <inline-svg src="/media/svg/icons/General/Trash.svg" />
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </div>
          </table>
          <div
            v-if="profile_cart && !profile_cart.record.profile_cart.length"
            class="text-center h5 pb-4"
          >
            ไม่มีข้อมูลสินค้า
          </div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
    </div>
    <div class="card card-custom px-6 py-4 card-stretch mt-4 align-center">
      <div class="d-flex">
        <div
          class="d-flex align-self-center fs-4"
          style="width: 220px; color: #f44336"
        >
          อัตราแลกเปลี่ยน
          {{ format_number(profile_cart && profile_cart.record.rate, 2) }}
        </div>
        <div class="d-flex ml-auto">
          <span
            class="mr-3 text-grey align-self-center fs-4"
            style="text-align: right"
          >
            สรุปยอด: ¥{{ format_number(total, 2) }} x ฿{{
              format_number(profile_cart ? profile_cart.record.rate : 0, 2)
            }}
            <span v-if="totalBahtShopee"> + ฿{{ totalBahtShopee }}</span>
            =
            <span style="margin-left: 4px; font-size: 18px; color: #333">
              {{ format_number(totalBaht, 2) }} บาท
            </span>
          </span>
          <button
            class="btn btn-success ml-4"
            style="width: 144px"
            @click="cartCheckout()"
          >
            ต่อไป
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import frag from "vue-frag";
import { UPDATE_CHECKOUT } from "@/core/services/store/checkout.module.js";
import InputNumber from "@/components/InputNumber";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import bigDecimal from "js-big-decimal";
import {
  GET_CARTS,
  UPDATE_AMOUNT_CART,
  DELETE_PROFILE_CART,
  UPDATE_CRAFTQC_CART
} from "@/graphql/cart";
import { WEBSITE } from "@/config";

export default {
  name: "CartList",
  mounted() {
    document.title = `ตะกร้าสินค้า | ${WEBSITE}`;
    this.selected = this.checkout;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `ตะกร้าสินค้า` }]);
  },
  data() {
    return {
      total: 0,
      totalBaht: 0,
      totalBahtShopee: 0,
      selected: {}
    };
  },
  computed: {
    ...mapGetters(["checkout"])
  },
  directives: {
    frag
  },
  components: {
    InputNumber
  },
  methods: {
    selectShop(event, vendor) {
      const checked = event.target.checked;
      if (checked) {
        this.selected[vendor._id] = [...vendor.items];
      } else {
        this.selected[vendor._id] = [];
      }
      this.calculate();
    },
    selectItem(event, vendor, item) {
      const checked = event.target.checked;
      let found = false;
      if (!this.selected[vendor._id]) {
        this.selected[vendor._id] = [];
      }
      if (this.selected[vendor._id]) {
        found = this.selected[vendor._id].find(x => x._id === item._id);
        if (!found && checked) {
          this.selected[vendor._id].push(item);
        } else if (found) {
          const index = this.selected[vendor._id].indexOf(found);
          this.selected[vendor._id].splice(index, 1);
          if (!this.selected[vendor._id].length) {
            delete this.selected[vendor._id];
          }
        }
      }
      this.calculate();
    },
    checkedAllItem(vendor_id, length_all) {
      return (
        this.selected[vendor_id] &&
        length_all === this.selected[vendor_id].length
      );
    },
    checkedItem(vendor_id, item) {
      return (
        this.selected[vendor_id] &&
        this.selected[vendor_id].find(x => x._id === item)
      );
    },
    cartCheckout() {
      const vendor = {};
      Object.keys(this.selected).map(key => {
        if (this.selected[key].length) {
          const v = this.profile_cart.record.profile_cart.find(
            x => x._id === key
          );
          if (v) {
            vendor[key] = {
              vendor: v.vendor,
              currency: v.currency,
              currencySign: v.currencySign,
              _id: v._id,
              guarantee: v.guarantee
            };
          } else {
            this.$message.error("ข้อมูลมีการเปลี่ยนแปลง กรุณารีเฟรช");
            return;
          }
        }
      });
      this.$store.dispatch(UPDATE_CHECKOUT, {
        selected: this.selected,
        vendor,
        rateOrder: this.profile_cart.record.rate,
        serviceCube: this.profile_cart.record.serviceCube,
        serviceKg: this.profile_cart.record.serviceKg
      });
      const count = Object.values(this.selected)
        .map(x => x.length)
        .reduce((total, val) => total + val, 0);
      if (!count) {
        this.$message.error("กรุณาเลือกสินค้า");
        return;
      }
      this.$router.push({ name: "checkout" });
    },
    async deleteCart(vendorId, itemId) {
      await this.$apollo.mutate({
        mutation: DELETE_PROFILE_CART,
        variables: {
          input: [
            {
              _id: vendorId,
              items: [{ _id: itemId }]
            }
          ]
        }
      });
      this.$apollo.queries.profile_cart.refetch();
    },
    async enableWoodCart(vendorId, enable) {
      await this.$apollo.mutate({
        mutation: UPDATE_CRAFTQC_CART,
        variables: {
          input: {
            id: vendorId,
            key: "guarantee",
            val: enable ? 1 : 0
          }
        }
      });
      this.$apollo.queries.profile_cart.refetch();
    },
    async updateAmount(vendorId, itemId, amount) {
      this.profile_cart.record.profile_cart.map(shop => {
        if (shop._id === vendorId) {
          shop.items.map(item => {
            if (item._id === itemId) {
              item.amount = amount;
              return;
            }
          });
        }
      });
      if (this.selected[vendorId]) {
        this.selected[vendorId].map(item => {
          if (item._id === itemId) {
            item.amount = amount;
            return;
          }
        });
      }
      this.calculate();
      await this.$apollo.mutate({
        mutation: UPDATE_AMOUNT_CART,
        variables: {
          input: {
            parent_id: vendorId,
            child_id: itemId,
            val: Number(amount) ?? 0
          }
        }
      });
    },
    format_number(val, decimal = 0) {
      return Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    },
    calculate() {
      let total = 0;
      let totalBahtShopee = 0;
      Object.values(this.selected).map(x => {
        const sum = x.reduce(
          (total, val) => total + (!val.currency ? val.price : 0) * val.amount,
          0
        );
        totalBahtShopee += x.reduce(
          (total, val) => total + (val.currency ? val.price : 0) * val.amount,
          0
        );
        total += sum;
      });
      if (this.profile_cart) {
        this.total = total;
        this.totalBaht =
          total * this.profile_cart.record.rate + totalBahtShopee;
        this.totalBahtShopee = totalBahtShopee;
      }
    }
  },
  watch: {
    profile_cart: {
      handler: function() {
        this.calculate();
      }
    },
    selected: {
      handler: function() {
        this.calculate();
      },
      deep: true
    }
  },
  apollo: {
    profile_cart: {
      query: GET_CARTS
    }
  }
};
</script>
